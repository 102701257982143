.footer {
  .footer-content {
    padding: 70px 0 100px 150px;
    display: flex;
    .left {
      h4 {
        opacity: 1;
        color: rgba(131, 131, 131, 1);
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: -1.26px;
        text-align: left;
        margin-top: 20px;
      }
    }
    .f-links {
      display: flex;
      flex-direction: column;
      a {
        color: rgba(131, 131, 131, 1);
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -1.18px;
        text-align: left;
        margin-bottom: 27px;
        text-decoration: none;
      }
    }
    .center {
      margin-right: 242px;
      margin-left: 242px;
    }
  }
}
.footerV2 {
  background-color: var(--black);
  .footer-content {
    .f-links {
      display: flex;
      gap: 10px !important;
      label {
      }
      a {
        margin-bottom: 0 !important;
        padding: 0 !important;
        color: var(--white) !important;
      }
    }
    .right {
      margin-right: 170px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .footer {
    .footer-content {
      padding: 70px 0 100px 50px;
      .left {
        h4 {
          font-size: 16px;
          margin-top: 20px;
        }
      }
      .f-links {
        a {
          font-size: 15px;
          margin-bottom: 27px;
        }
      }
      .center {
        margin-right: 150px;
        margin-left: 100px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .footer .footer-content .center {
    margin-right: 60px;
    margin-left: 60px;
  }
  .footerV2 .footer-content .right {
    margin-right: 60px;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    .footer-content {
      padding: 70px 0 100px 30px;
      .left {
        h4 {
          font-size: 16px;
          margin-top: 20px;
        }
      }
      .f-links {
        a {
          font-size: 15px;
          margin-bottom: 27px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    .footer-content {
      padding: 70px 23px 70px 23px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      grid-gap: 50px;
      border-top: 1px solid var(--darkest-grey-25);
      position: relative;
      .left {
        grid-row: 1 / 2;
        .site-logo {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        h4 {
          font-size: 16px;
          margin-top: 0px;
          position: absolute;
          right: 19px;
          top: 110px;
        }
      }
      .f-links {
        a {
          font-size: 15px;
          margin-bottom: 27px;
        }
      }
      .center {
        margin-right: 0px;
        margin-left: 00px;
        grid-row: 2 / 3;
      }
      .right {
        grid-row: 2/ 3;
        margin-right: 0 !important;
      }
    }
  }
}
