.collecting {
  padding-top: 74px;
  padding-bottom: 46px;
  overflow-x: hidden;
  .collection-content {
    display: flex;
    padding-bottom: 60px;
    border-bottom: 1px solid var(--light-grey);
    width: 100%;
    .left-content {
      button {
        border-radius: 25px;
        width: 218px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -1px;
      }
    }
    .right-content {
      margin-left: 128px;
      h1 {
        text-transform: uppercase;
        font-size: 9rem;
        line-height: 130px;
        span {
          font-family: var(--didot-italic);
          font-weight: 400;
        }
      }
      p {
        width: 370px;
        margin-top: 24.61px;
        span {
          font-weight: 700;
        }
      }
    }
  }
  .collection-footer {
    margin-top: 30px;
    p {
      font-size: 17px;
      font-style: italic;
      letter-spacing: -0.5px;
      text-transform: uppercase;
      img {
        margin-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .collecting {
    padding: 73px 0px 30px 47px;

    .collection-content {
      .right-content {
        margin-left: 128px;
        width: 100%;
        h1 {
          text-transform: uppercase;
          font-size: 9rem;
          span {
            font-family: var(--didot-italic);
            font-weight: 400;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .collecting {
    padding: 73px 0px 30px 47px;

    .collection-content {
      .right-content {
        margin-left: 100px;
        h1 {
          font-size: 80px;
          letter-spacing: -7px;
          line-height: 68px;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .collecting {
    padding: 73px 0px 30px 36px;

    .collection-content {
      padding-bottom: 60px;
      width: auto;
      .left-content {
        button {
          width: 156px;
          height: 36px;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.6px;
        }
      }

      .right-content {
        margin-left: 50px;
        h1 {
          font-size: 60px;
          letter-spacing: -7px;
          line-height: 63px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .collecting {
    padding: 0px 23px 0px 23px;
    .collection-content {
      padding-bottom: 50.24px;
      flex-direction: column;
      // border-bottom: none;
      .left-content {
        order: 2;
        button {
          width: 100%;
          height: 36px;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.6px;
        }
      }
      .right-content {
        margin-left: 0px;
        margin-bottom: 60.76px;
        h1 {
          font-size: 60px;
          letter-spacing: -5.5px;
          line-height: 54px;
        }
        p {
          width: 100%;
          span {
            font-weight: unset;
          }
        }
      }
    }
    .collection-footer {
      p {
        img {
          margin-right: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .collecting {
    .collection-content {
      .right-content {
        h1 {
          font-size: 50px;
        }
      }
    }
  }
}
