.rotate-360 {
  animation: rotateAnim 1s linear 0s infinite normal forwards;
}
@keyframes rotateAnim {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.trans-7 {
  transition: all 0.7s linear;
}

.translateX-1000 {
  transform: translateX(-1000px);
}

.translateY-1000 {
  transform: translateY(1000px);
}

.translateX-0 {
  transform: translateX(0);
}

.artwork-hover {
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(110%);
  }
}

.row-hover {
  &:hover {
    background: var(--off-white);
  }
}

.fade {
  transition: unset !important;
}
.offcanvas {
  transition: unset !important;
}

.shimmer-after {
  position: relative;
  &::after {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 5s infinite;
  }
}
