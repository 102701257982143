.analytics {
  .create-artwork {
    width: 278px;
  }
  .valueBox-wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    .value-box {
      width: 100%;
      height: 111px;
      border: 1px solid var(--lightest-grey);
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 30px;
    }
  }
  .analysis-chart {
    display: flex;
    gap: 40px;
    justify-content: space-around;
    .analysis {
      position: relative;
      label {
        position: absolute;
        left: 0;
        top: 30px;
      }
      .volume-chart {
        max-width: 500px !important;
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .analytics {
    .create-artwork {
      width: 278px;
    }
    .valueBox-wrapper {
      gap: 30px;
      .value-box {
        h5 {
          font-size: 20px !important;
        }
      }
    }
    .analysis-chart {
      display: flex;
      gap: 40px;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      .analysis {
        label {
          position: absolute;
          left: 0;
          top: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .analytics {
    .create-artwork {
      // width: 278px;
      width: 100%;
    }
    .valueBox-wrapper {
      flex-direction: column;
      gap: 30px;
      .value-box {
        width: 100%;
        height: 80px;
      }
    }
    .analysis-chart {
      .analysis {
        .volume-chart {
          width: 350px !important;
        }
      }
    }
  }
}
