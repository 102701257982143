.white-list {
  .admin-content-head {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
  .search-blocklist {
    .right {
      .search-form {
        border-radius: 30px;
        // width: 100%;
        @include flex-center;
        // padding: 0;
        img {
          // margin-right: 0;
        }
        input {
          // display: none;
        }
        span {
          img {
            // display: none;
          }
        }
      }
      .search-form-mb {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 62px;
        z-index: 99;
        width: 100% !important;
        padding: 0 13px;
        @include flex-center(space-between);
        background-color: var(--white);
        .search-form {
          border-radius: 30px;
          max-width: 85%;
          width: 100%;
          @include flex-center;
          padding: 0 15px !important;
          img {
            margin-right: 0;
          }
          input {
            display: block;
          }
          span {
            img {
              display: block;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .white-list {
    .search-blocklist {
      .right {
        .search-form {
          border-radius: 8px;
          width: 53px;
          height: 53px;
          @include flex-center;
          padding: 0;
          img {
            margin-right: 0;
          }
          input {
            display: none;
          }
          span {
            img {
              display: none;
            }
          }
        }
        .add-Btn {
          background-color: red;
          width: 53px;
          height: 53px;
        }
      }
    }
    .admin-content-head {
      align-items: center;
    }
  }
}
