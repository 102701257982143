.explore-art-wrapper {
  padding: 82px 120px 100px 120px;
}
.explore-art {
  .explore-art-content {
    // max-width: 1400px;
    margin: 0 auto;
    h4 {
      font-size: 34px;
    }

    .profile-tabs {
      margin-top: 70px;
      .profile-tabs .nav-item .nav-link {
        // margin-right: 32px;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
      }
      .profile-tabs .nav-item .nav-link.disabled {
        cursor: pointer !important;
        &:hover {
          cursor: pointer !important;
          color: black !important;
          background-color: red !important;
        }
      }
      // .profile-tabs .nav-item .nav-link:disabled {
      //   cursor: pointer !important;
      //   &:hover {
      //     cursor: pointer !important;
      //     color: black !important;
      //     background-color: red !important;
      //   }
      // }
    }
  }
}
@media screen and (max-width: 1440px) {
  .explore-art-wrapper {
    padding: 82px 110px 100px 110px;
  }
}
@media screen and (max-width: 1100px) {
  .explore-art-wrapper {
    padding: 82px 80px 100px 80px;
  }
}
@media screen and (max-width: 992px) {
  .explore-art-wrapper {
    padding: 82px 40px 70px 40px;
  }
}
@media screen and (max-width: 600px) {
  .explore-art-wrapper {
    padding: 82px 20px 100px 20px;
  }
}
