// Banner.scss
.banner {
  background: #333; /* Dark background color */
  color: #fff; /* White text color */
  padding: 10px 20px; /* Adjusted padding */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */

  .banner-message {
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: center; /* Centered text */
    flex-grow: 1; /* Allow the message to take remaining space */
  }

  .close-button {
    cursor: pointer;

  }
}
