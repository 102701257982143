// HEADING STYLES

h1 {
  font-family: "Inter";
  font-size: 112px;
  font-weight: 600;
  line-height: 110px;
  letter-spacing: -10px;
  color: var(--black);
}

/* Style for H2 */

h2 {
  font-size: 101px;
  font-weight: 400;
  line-height: 88px;
  // letter-spacing: -9px;
  text-transform: uppercase;
}

/* Style for H2 */

.italic {
  font-family: var(--didot-italic), "Times New Roman", Times, serif;
  font-size: 105px;
  line-height: 75px;
  letter-spacing: -6px;
  text-transform: uppercase;
  color: var(--black);
}

/* Style for H3 */

h3 {
  font-size: 60px;
  font-weight: 300;
  line-height: 72px;
  letter-spacing: -3px;
}

/* Style for H4 */

h4 {
  font-size: 46px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -2px;
}
h4.medium-head {
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -2px;
}

/* Style for H5 */

h5.italic-head {
  font-family: var(--didot-italic), "Times New Roman", Times, serif;
  font-size: 29px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -1.5px;
  text-transform: uppercase;
  // font-style: italic;
  color: var(--black);
}
h5 {
  font-size: 29px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -1.5px;
  text-transform: uppercase;
}
/* Style for H6 */

h6 {
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -1px;
  font-style: italic;
}

/*-------------------------------- */

// LABEL STYLES

/* Default label styles */

label {
  font-family: "Inter", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: var(--medium-grey);
}

/* Medium label */

label.large {
  font-size: 18px;
  line-height: 22px;
}
label.medium {
  font-size: 14px;
  line-height: 18px;
}

/* Small label */

label.small {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.2px;
}

/*-------------------------------- */

// PARAGRAPH STYLES

/* Default paragraph styles */

p {
  font-family: "Inter", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: var(--black);
  letter-spacing: -0.5px;
}
/* Large paragraph */

.body-large {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1px;
}

/* Medium paragraph */

.body-medium {
  font-size: 14px;
  line-height: 18px;
}

/* Small paragraph */

.body-small {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02px;
}

/* Extra small paragraph */

.body-extra-small {
  font-size: 10px;
  line-height: 13px;
  color: var(--medium-grey);
}

/* mobile nav links  */

.mb-nav-link {
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1.3px;
  text-align: center;
  color: var(--black);
}

/* font-weight*/

.fw-500 {
  font-weight: 500;
}

.font-normal {
  font-style: unset;
}
.text-underline {
  text-decoration: underline;
}

.font-18 {
  font-size: 18px !important;
}
.font-26 {
  font-size: 26px !important;
}
.fw-medium {
  font-weight: 500 !important;
}
