.nft-table {
  .art-img {
    width: 49px;
    height: 49px;
    border: 1px solid var(--black);
    padding: 2px;
    margin-right: 11px;
  }
  .ant-table-thead {
    tr {
      .ant-table-cell {
        font-family: "Inter", Arial, sans-serif;
        text-transform: uppercase;
        color: var(--medium-grey);
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: -0.2px;
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        &::before {
          display: none;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      .ant-table-cell {
        padding-left: 0;
        padding-right: 0;
        &.ant-table-cell-row-hover {
          background: transparent !important;
        }
      }
    }
  }

  .ant-table-pagination {
    display: none;
  }
}
.table-popover {
  .ant-popover-inner {
    background: #fff !important;
    padding: 10px 20px !important;
    border: 1px solid black !important;
  }
  .ant-popover-title {
    margin-bottom: 0 !important;
    min-height: auto !important;
    font-family: "Inter";
    font-weight: 600;
  }
}
