.file-thumbnails {
  position: relative;
  width: fit-content;
  .bin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    z-index: 2;
    cursor: pointer;
  }
  &:hover .file-thumbnail::after {
    background-color: var(--black-15);
  }
}

.file-thumbnail {
  width: 206px;
  height: 147px;
  border: 1px dashed var(--light-grey);
  background-color: var(--off-white);
  position: relative;
  @include flex-center;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in;
  }
}

@media screen and (max-width: 600px) {
  .file-thumbnails {
    width: 100%;
  }
  .file-thumbnail {
    width: 100%;
    height: 271px;
  }
}

.fit-content {
  width: fit-content;
}
