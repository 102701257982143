.dashboard-wrapper {
  .data-boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    .data-box {
      width: 100%;
      height: 141px;
      @include flex-center;
      flex-direction: column;
      border: 1px solid var(--off-white);
      border-radius: 8px;
    }
  }
  .volume {
    margin-top: 70px;

    #chart_div {
      width: 100%;
      height: fit-content;
      border: 1px solid var(--off-white);
      margin-top: 32px;
      .volume-chart {
        min-height: unset !important;
        display: flex;
        width: 100% !important;

        .apexcharts-canvas {
          min-height: unset !important;
          height: 100% !important;
          width: 100% !important;

          #SvgjsSvg2685 {
            min-height: unset !important;
            height: 100% !important;
            width: 100% !important;
            foreignObject {
              min-height: unset !important;
              height: 100% !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  .notification {
    margin-top: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width: 992px) {
  .dashboard-wrapper {
    .data-boxes {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 600px) {
  .dashboard-wrapper {
    margin-top: 90px;
    .data-boxes {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
