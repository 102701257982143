.my-filter {
  // background-color: red;
  height: 300px;
  margin-right: 50px;
  margin-top: 33px;
}
.row-layout {
  position: relative;
  .c1 {
    max-width: 449px;
    width: 30%;
  }
  .c2 {
    max-width: 325px;
    width: 20%;
  }
  .c3 {
    max-width: 348px;
    width: 20%;
  }
  .c4 {
    max-width: 360px;
    width: 20%;
  }
  .c5 {
    min-width: 15px;
    width: 10%;
  }
  .row-layout-head {
    height: 55px;
    @include d-center;
    z-index: 1;
    border-bottom: 1px solid var(--lightest-grey);
    position: sticky;
    top: 134px;
    background: var(--white);
  }
  .row-layout-body {
    .row-content {
      height: 76px;
      border-bottom: 1px solid var(--lightest-grey);

      .artwork-item {
        .art-img {
          width: 49px;
          height: 49px;
          border: 1px solid var(--black);
          padding: 2px;
          margin-right: 11px;
        }
        .user-name {
          .artist-profile {
            font-weight: 700;
            font-size: 15px;
            text-transform: none;
            &:hover {
              color: var(--black);
              text-decoration: underline;
              .artist-popup {
                display: block;
              }
            }
            img {
              margin-left: 5px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .pricing {
        label {
          margin-right: 7px;
          margin-left: 11px;
          text-transform: uppercase;
          @include d-center;
          img {
            margin-right: 11px;
          }
          p {
            margin-left: 7px;
          }
        }
      }

      // .artist-popup {
      //   position: absolute;
      //   left: -110px;
      //   top: -220px;
      //   z-index: 3;
      //   background-color: var(--white);
      //   width: 331px;
      //   height: 216px;
      //   border: 1px solid var(--black);
      //   padding: 21px 15px 15px 15px;
      //   display: none;
      //   .pop-head {
      //     display: flex;
      //     align-items: center;
      //     padding-bottom: 15px;
      //     border-bottom: 1px solid var(--lightest-grey);
      //     .user-img {
      //       width: 44px;
      //       height: 44px;
      //       padding: 2px;
      //       border: 1px solid var(--black);
      //       border-radius: 100%;
      //     }
      //     p {
      //       font-weight: 600;
      //       color: var(--black);
      //       text-decoration: underline;
      //       img {
      //         margin-left: 5px;
      //         width: 18px;
      //         height: 18px;
      //       }
      //     }
      //   }
      //   .popup-body {
      //     display: flex;
      //     label.medium {
      //       font-weight: 400;
      //       margin-bottom: 6px;
      //     }
      //     .created {
      //       padding-top: 18px;
      //       display: flex;
      //       flex-direction: column;
      //       &:first-child {
      //         margin-right: 90px;
      //       }
      //     }
      //   }
      //   button {
      //     margin-top: 20px;
      //   }
      // }
    }
  }
}

@media screen and (max-width: 600px) {
  .row-layout {
    position: relative;
    .c1,
    .c2,
    .c3,
    .c4,
    .c5 {
      max-width: none !important;
      min-width: none;
      width: fit-content;
    }

    .row-layout-head {
      justify-content: space-between;
    }

    .row-layout-body {
      .row-content {
        justify-content: space-between;
        .pricing {
          justify-content: end;
        }
        .artwork-item {
          .user-name {
            .artist-profile {
              &:hover .artist-popup {
                display: none;
              }
            }
          }
        }
      }
    }

    .item-hide {
      display: none !important;
    }
  }
}
