.userProfile {
  .user-img {
    width: 40px;
    height: 40px;
    padding: 1px;
    border: 1px solid var(--black);
    border-radius: 100%;
    margin-right: 10px;
  }
}
