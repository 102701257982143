.spotlight-wrapper {
  .spotlight-wrapper-content {
    .artist-details {
      .head {
        margin-bottom: 38px;
      }
    }

    textarea {
      font-weight: 500;
      &::placeholder {
        font-weight: 500;
        color: var(--light-grey);
      }
    }

    .short-bio {
      textarea {
        min-height: 70px;
      }
    }
    .bio-section {
      textarea {
        min-height: 130px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .spotlight-wrapper-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width: 600px) {
  .spotlight-wrapper-content {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 340px) {
  .admin-content-head {
    .date-range-mb {
      .dropdown {
        width: 135px;
        height: 43px;
        .dropdown-toggle {
          padding: 0 14px;
        }
      }
    }

    h6 {
      img {
        margin-right: 0 !important;
      }
    }
  }
}
