.Applyform {
  padding: 50px 436px 150px 417px;
  .Applyform-main {
    display: flex;
    flex-direction: column;
    // align-items: center;
    row-gap: 28px;
    input[type="checkbox"]:checked {
      accent-color: #000000;
    }
    .top {
      padding: 36px 30.25px 36.13px 32px;
      background: #ffffff;
      border: 1px solid #000000;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      img {
        margin-left: -3px;
      }
      h1 {
        font-family: "Trap700";
        font-style: normal;
        font-size: 40px;
        line-height: 110%;
        display: flex;
        align-items: center;
        color: #000000;
        margin-top: 91px;
        margin-bottom: 36px;
      }
      p {
        font-family: "Trap500";
        font-style: normal;
        font-size: 25px;
        line-height: 110%;
        display: flex;
        align-items: center;
        color: #010101;
        margin-bottom: 29px;
      }
      label {
        font-family: "Trap500";
        font-style: normal;
        font-size: 25px;
        line-height: 110%;
        display: flex;
        align-items: center;
        color: #000000;
        margin-top: 80px;
        margin-bottom: 25px;
      }
      ul {
        padding-left: 37px;
        li {
          list-style-type: square;
          font-family: "Trap500";
          font-style: normal;
          font-size: 25px;
          line-height: 112%;
          list-style-type: none;
          //   display: flex;
          //   align-items: center;
          color: #000000;
          position: relative;
          &::before {
            content: "";
            width: 5px;
            height: 5px;
            position: absolute;
            left: -20px;
            right: 0px;
            top: 8px;
            bottom: 0;
            background: black;
          }
        }
      }
      span {
        margin-top: 88px;
        font-family: "Trap500";
        font-style: normal;
        font-size: 25px;
        line-height: 110%;
        display: flex;
        align-items: center;
        color: #000000;
        margin-left: -0.5px;
      }
    }

    .form-box {
      padding: 28px 38px 54px 38px;
      border: 1px solid #000000;
      border-radius: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      label {
        font-family: "Trap500";
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 110%;
        color: #060606;
        margin-bottom: 10px;
        span {
          color: rgba(255, 9, 9, 1);
        }
      }
      p {
        font-family: "Trap500";
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 110%;
        color: #060606;
      }
      input[type="text"],
      input[type="email"],
      input[type="url"] {
        font-family: "Trap400";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 110%;
        color: #000000;
        border: none;
        outline: none;
        border-bottom: 1px solid #b4b4b4;
        max-width: 824px;
        width: 100%;
        margin-top: 60px;
        &::placeholder {
          font-family: "Trap400";
          color: #b4b4b4;
        }
      }

      .check {
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          width: 32px;
          height: 32px;
          border: 1px solid #000000;
          border-radius: 3px;
        }
        label {
          font-family: "Trap500";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 110%;
          color: #000000;
          margin-bottom: 0;
          margin-left: 16px;
        }
      }
    }
    .bottom {
      margin-top: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        border: none;
        background: #111111;
        border-radius: 28px;
        font-family: "Trap700";
        font-style: normal;
        font-size: 18px;
        line-height: 20px;
        color: #ffffff;
        padding: 18px 87px;
      }
      a {
        font-family: "Trap700";
        font-style: normal;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        text-decoration: none;
      }
    }
  }
}

.form-modal .modal-title {
  font-family: "Trap700";
  font-style: normal;
  font-size: 30px;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  text-transform: capitalize;
  color: #000000;
}
.modalClose {
  font-family: "Trap500";
  font-style: normal;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background: transparent;
  border: none;
  color: #000000;
  margin: 0px auto;
  &:hover {
    background: transparent;
    color: #000000;
  }
}
.form-modal .modal-body {
  font-family: "Trap400";
  font-style: normal;
  font-size: 20px;
  line-height: 147%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: #000000;
  a {
    font-family: "Trap400";
    font-style: normal;
    font-size: 20px;
    line-height: 147%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    text-decoration: underline;
  }
}
////////////////////////////////////Media Queries////////////////////////////////////

@media only screen and (max-width: 1440px) {
  .Applyform {
    padding: 50px 300px 150px 300px;
  }
}

@media only screen and (max-width: 1280px) {
  .Applyform {
    padding: 50px 150px 150px 150px;
  }
}

@media only screen and (max-width: 600px) {
  .modal-content {
    // width: 96vw !important;
    width: 100%;
  }
  .Applyform {
    padding: 50px 20px 100px 20px;

    .Applyform-main {
      display: flex;
      flex-direction: column;
      // align-items: center;
      row-gap: 28px;
      .top {
        padding: 20px;
        background: #ffffff;
        border: 1px solid #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        img {
          margin-left: -3px;
        }
        h1 {
          font-family: "Trap700";
          font-style: normal;
          font-size: 25px;
          line-height: 110%;
          display: flex;
          align-items: center;
          color: #000000;
          margin-top: 20px;
          margin-bottom: 15px;
        }
        p {
          font-family: "Trap500";
          font-style: normal;
          font-size: 18px;
          line-height: 110%;
          display: flex;
          align-items: center;
          color: #010101;
          margin-bottom: 10px;
        }
        label {
          font-family: "Trap500";
          font-style: normal;
          font-size: 20px;
          line-height: 110%;
          display: flex;
          align-items: center;
          color: #000000;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        ul {
          padding-left: 37px;
          li {
            list-style-type: square;
            font-family: "Trap500";
            font-style: normal;
            font-size: 18px;
            line-height: 115%;
            list-style-type: none;
            //   display: flex;
            //   align-items: center;
            color: #000000;
            position: relative;
            &::before {
              content: "";
              width: 5px;
              height: 5px;
              position: absolute;
              left: -20px;
              right: 0px;
              top: 8px;
              bottom: 0;
              background: black;
            }
          }
        }
        span {
          margin-top: 10px;
          font-family: "Trap500";
          font-style: normal;
          font-size: 18px;
          line-height: 110%;
          display: flex;
          align-items: center;
          color: #000000;
          margin-left: -0.5px;
        }
      }

      .form-box {
        padding: 20px;
        border: 1px solid #000000;
        border-radius: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        label {
          font-family: "Trap500";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 110%;
          color: #060606;
          margin-bottom: 5px;
          span {
            color: rgba(255, 9, 9, 1);
          }
        }
        p {
          font-family: "Trap500";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 110%;
          color: #060606;
        }
        input[type="text"],
        input[type="email"],
        input[type="url"] {
          font-family: "Trap400";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 110%;
          color: #b4b4b4;
          border: none;
          outline: none;
          border-bottom: 1px solid #b4b4b4;
          max-width: 824px;
          width: 100%;
          margin-top: 40px;
          &::placeholder {
            font-family: "Trap400";
            color: #b4b4b4;
          }
        }

        .check {
          display: flex;
          align-items: center;
          input[type="checkbox"] {
            width: 24px;
            height: 24px;
            border: 1px solid #000000;
            border-radius: 3px;
          }
          label {
            font-family: "Trap500";
            font-style: normal;
            font-size: 18px;
            line-height: 110%;
            color: #000000;
            margin-bottom: 0;
            margin-left: 10px;
          }
        }
      }
      .bottom {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          border: none;
          background: #111111;
          border-radius: 28px;
          font-family: "Trap700";
          font-style: normal;
          font-size: 18px;
          line-height: 20px;
          color: #ffffff;
          padding: 10px 25px;
        }
        a {
          font-family: "Trap700";
          font-style: normal;
          font-size: 18px;
          line-height: 20px;
          color: #000000;
          text-decoration: none;
        }
      }
    }
  }
}
