.siteSetting {
  padding-bottom: 100px;
  .siteSetting-content {
    .commission {
      padding-bottom: 40px;
      border-bottom: 1px solid var(--lightest-grey);
    }
    .input-boxs {
    }
  }
}

@media screen and (max-width: 1024px) {
  .siteSetting {
    .siteSetting-content {
      padding-left: 16px;
      padding-right: 16px;
      .input-boxs {
        flex-direction: column;
      }
    }
  }
}
